import React from 'react';
import { NavLink } from 'react-router-dom';

function CardService({
	title,
	intro,
	intro2,
	description,
}) {
	return (
		<>
			<div className="d-flex align-items-center justify-content-between mb-4">
				<h2 className="first-gold fw-bolder mb-0">{title}</h2>
			</div>

			<div className="card shadow border-0 rounded-4 mb-5">
				<div className="card-body" style={{padding:'2.35rem'}}>
					<div className="row align-items-center gx-5">
						<div className="lead first-blue mb-4"> {intro} <br/>
						 {intro2}</div>
						<div>{description}</div>
					</div>
				</div>
				<NavLink
					to="/richieste"
					className="btn-first-gold btn-lg px-5 py-3 fs-6 fw-bolder card-btn-contact"
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						marginBottom: '5vh',
					}}>
					
					{' '}
					<span className='card-txt'>Contattami</span>
				</NavLink>
			</div>
		</>
	);
}

export default CardService;
