import React from 'react';
import './Footer.css';

function Footer() {
	return (
		<footer className=" bg-white py-4 mt-auto">
			<div className="container px-5 ">
				{/* 				<div class="row align-items-baseline justify-content-between flex-column flex-sm-row">
				 */}{' '}
				<ul className="social-icons">
				<span className="visually-hidden">Link ai miei social e mail</span>

				{/* Facebook */}
					<li >
					<a href="https://www.facebook.com/nicola.noro">
					<svg
					fill="currentColor"
					viewBox="0 0 16 16"
					height="1em"
					width="1em"
					aria-labelledby='icontitle icondesc'
					>
					<title id='icontitle'> icona Facebook</title>
					
					<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
					</svg>
					<span className="visually-hidden">vai alla mia pagina Facebook</span>	
					</a>
					</li>

					{/* Linkedin */}
					<li>
					<a href="https://www.linkedin.com/in/nicola-noro-87b475171/">
					<svg
					viewBox="0 0 960 1000"
					fill="currentColor"
					height="1.25em"
					width="1em"
					aria-labelledby='icontitle icondesc'
					>
					<title id='icontitle'> icona LinkedIn</title>
					
					<path d="M480 20c133.333 0 246.667 46.667 340 140s140 206.667 140 340c0 132-46.667 245-140 339S613.333 980 480 980c-132 0-245-47-339-141S0 632 0 500c0-133.333 47-246.667 141-340S348 20 480 20M362 698V386h-96v312h96m-48-352c34.667 0 52-16 52-48s-17.333-48-52-48c-14.667 0-27 4.667-37 14s-15 20.667-15 34c0 32 17.333 48 52 48m404 352V514c0-44-10.333-77.667-31-101s-47.667-35-81-35c-44 0-76 16.667-96 50h-2l-6-42h-84c1.333 18.667 2 52 2 100v212h98V518c0-12 1.333-20 4-24 8-25.333 24.667-38 50-38 32 0 48 22.667 48 68v174h98" />
					</svg>
					<span className="visually-hidden">vai al mio profilo LinkedIn</span>
					</a>
					</li>

					{/* Instagram */}
					<li style={{marginLeft: '-10px',marginRight: '-5px'}}>
					<a href="https://www.instagram.com/nicolanorolis/">
					<svg
					baseProfile="tiny"
					viewBox="0 0 24 24"
					fill="currentColor"
					height="1.3em"
					width="1.35em"
					aria-labelledby='icontitle icondesc'
					>
					<title id='icontitle'> icona Instagram</title>
					<path d="M12 3c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm2.8-2c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2zM12 19c-3.9 0-7-3.1-7-7h3c0 2.2 1.8 4 4 4s4-1.8 4-4h3c0 3.9-3.1 7-7 7z" />
					</svg>
					<span className="visually-hidden">seguimi su Instagram</span>
					</a>
					</li>

					{/* YouTube */}
					<li style={{marginLeft: '-10px',marginRight: '-5px'}}>
					<a href="https://www.youtube.com/channel/UC31UGsIrRHdZdUWLeRiqHaA">
					<svg
					viewBox="0 0 24 24"
					fill="currentColor"
					height="1.3em"
					width="1.3em"
					aria-labelledby='icontitle icondesc'
					>
					<title id='icontitle'> icona YouTube</title>
					<path d="M8.48 13.14h.73v3.61h.7v-3.61h.73v-.61H8.48zM12.17 16c-.12.14-.53.42-.53.02v-2.39h-.62v2.61c0 .79.79.58 1.16.17v.34h.62v-3.12h-.62V16h-.01zM14.48 13.61c-.36 0-.59.27-.59.27v-1.36h-.63v4.23h.63v-.24s.21.28.59.28c.33 0 .58-.29.58-.69v-1.73c0-.47-.22-.76-.58-.76zm-.07 2.41c0 .23-.16.34-.37.25a.479.479 0 01-.15-.11v-1.94c.04-.04.09-.07.13-.1.22-.11.39.06.39.29v1.61zM16.72 15.86c0 .24-.13.4-.28.41-.16.01-.32-.12-.32-.41v-.59h1.19v-.8a.91.91 0 00-.26-.66.919.919 0 00-.63-.24c-.22 0-.45.07-.63.21-.19.15-.31.38-.31.69v1.4c0 .28.09.5.23.66.17.18.4.28.64.29.29.01.6-.11.78-.36.11-.15.18-.35.18-.59v-.16h-.59v.15zm-.6-1.39c0-.17.1-.37.29-.37s.31.18.31.37v.32h-.6v-.32z" />
					<path d="M12.97 3a9 9 0 10.001 18.001A9 9 0 0012.97 3zm1.58 3.37h.8v2.68c0 .17.08.17.11.17.12 0 .3-.13.39-.22V6.36h.8V9.9h-.8v-.31c-.11.1-.22.18-.33.24-.15.08-.29.11-.43.11a.52.52 0 01-.41-.17c-.09-.11-.13-.28-.13-.49V6.37zM12 7.3c0-.55.45-1 1-1s1 .45 1 1V9c0 .55-.45 1-1 1s-1-.45-1-1V7.3zM9.92 5.15l.48 1.76.49-1.76h.91l-.94 2.78V9.9h-.89V7.93l-.96-2.78h.91zm7.9 12.54c-.51.5-4.83.51-4.83.51s-4.31-.01-4.83-.51c-.51-.5-.51-2.99-.51-3.01 0-.01 0-2.5.51-3.01.51-.5 4.83-.51 4.83-.51s4.31.01 4.83.51c.51.5.52 2.99.52 3.01 0 0 0 2.5-.52 3.01z" />
					<path d="M12.98 9.35c.17 0 .25-.1.26-.26v-1.9c0-.13-.13-.24-.25-.24s-.25.1-.25.24v1.9c0 .15.07.25.24.26z" />
					</svg>
					<span className="visually-hidden">vai al mio canale YouTube</span>
					</a>
					</li>

					{/* Mail */}
					<li>
					<a href="mailto:info@nicolanoro.com">
					<svg
					viewBox="0 0 16 16"
					fill="currentColor"
					height="1em"
					width="1em"
					aria-labelledby='icontitle icondesc'
					>
					<title id='icontitle'>icona mail</title>
					<path
						fill="currentColor"
						d="M8 0a8 8 0 100 16A8 8 0 008 0zM4 4h8c.143 0 .281.031.409.088L8 9.231 3.591 4.088A.982.982 0 014 4zm-1 7V5l.002-.063 2.932 3.421-2.9 2.9A.967.967 0 013 11zm9 1H4c-.088 0-.175-.012-.258-.034L6.588 9.12l1.413 1.648L9.414 9.12l2.846 2.846a.967.967 0 01-.258.034zm1-1c0 .088-.012.175-.034.258l-2.9-2.9 2.932-3.421L13 5v6z"
					/>
					</svg>
					<span className="visually-hidden">Scrivimi</span>
					</a>
					</li>
					
				</ul>
				<br/> <br/>
				<section className="copyright text-center">
				<div className="small m-0">Realizzato da Tatiana Motterle . 2023 </div>
				</section>
			</div>
	
		</footer>
	);
}

export default Footer;
