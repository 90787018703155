import React from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from './Components/NavBar';
import Footer from './Components/Footer';

function AppLayout() {
	return (
		<div className="app-layout">
			<NavBar />
			<Outlet />
			<Footer />
		</div>
	);
}

export default AppLayout;
