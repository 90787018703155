import React, {useState} from 'react';
import './CurriculumVitae.css';



function CurriculumVitae() {
	
	/* const [show, setShow] = useState(false); */
	const [show, setShow] = useState(Array(7).fill(false));

	

	function toggle(index) {
		const updatedShow = [...show];
		updatedShow[index] = !updatedShow[index];
		setShow(updatedShow);
		
	  }
	  
/* Srcdoc for accessibility */
	  const srcDocContent = `
	  <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:relative;width:100%}</style>
	  <a href="https://www.youtube.com/embed/kaVZNDIZdUQ?si=rMEyMgHwVe1hrfkc?autoplay=1">
		<img
		  src="picYT.png"
		  alt="Video: Interpretazione LIS di E yo mamma di Coez"
		/>
		<span>&#x25BA;</span>
	  </a>
	`;

	return (
		<body className="d-flex flex-column h-100">
			<main className="flex-shrink-0">
				<div className="container px-5 my-5">
					<div className="text-center mb-5">
						<h1 className="display-5 fw-bolder mb-0 first-blue focus-visible tabindex=0">
							Curriculum Vitae
						</h1>
						
					</div>
					<div className="row gx-5 justify-content-center ">
						<div className="col-sm-12 col-md-12 col-lg-11 col-xl-9 col-xxl-8">
							{/* Collaborazioni Section*/}
				<section>
							<div className="d-flex align-items-center justify-content-between mb-4">
				<h2 className="first-gold fw-bolder mb-0">Le mie collaborazioni</h2>
				</div>
									
				{/* Card */}
				<div className="card shadow border-0 rounded-4 mb-5">


			{/* [1] Memberships */}	

				<div className="card-body p-5" style={{marginBottom: "-100px"}}>
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<div className="bg-light p-4 rounded-4">
							
							
								<div className="fw-bolder job" >Membro di:</div>
								<br />
								<div className="medium text-muted">EFSLI - Forum Europeo degli interpreti delle lingue dei segni </div>
								<div className="medium text-muted">
								<a href="https://efsli.org/">https://efsli.org/
								<span className="visually-hidden">vai alla pagina di Efsli</span>
								</a>
								</div>
								<br />
								<div className="medium text-muted">ANIMU - Associazione Nazionale Interpreti Lingua dei segni Italiana  </div>
																<div className="medium text-muted">
								<a href="https://www.animu.it/">https://www.animu.it/
								<span className="visually-hidden">vai alla pagina di Animu</span>
								</a>
								</div>
								<br />
								<div className="medium text-muted">Teach For Italy - Insegnare per l'italia </div>
																<div className="medium text-muted">
								<a href="https://www.teachforitaly.org/">https://www.teachforitaly.org/
								<span className="visually-hidden">vai alla pagina di Teach For Italy</span>
								</a>
								</div>
								
								<div className='text-center'>
												
							{/* <button className="btn-sm fw-bolder cv-btn" onClick={() => toggle(1)}
								>
								  {show[1] ? 'Leggi di meno' : 'Leggi di più'}</button> */}
								  </div>
								{/* <div>
								{show[1] && 
								
								<ul>
								
									<li>Traduzione di video interviste in lingua dei segni italiana</li>
									<li>Interpretazione simultanea dello spettacolo in lingua dei segni
									italiana</li>
									<li>Consulenza sull'accessibilità dello spettacolo</li>
									<li>Formazione su inclusione e accessibilità</li>
									
								</ul>
								
								}
							</div> */}
								</div>
							<br />
							
							
						</div>
						
					</div>
				</div>
				
			
			{/* [2] - Regione Veneto*/}
				<div className="card-body p-5" style={{marginBottom: "-100px"}}>
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							<div className="small text-muted">12/2021 – 12/2024 </div>
							
								<div className="fw-bolder job">Insegnante di LIS/LISt - Progetto Formativo Scuole LIS/LISt </div>
								<div className="medium text-muted" > <i>Regione Veneto, Assessorato Sanità, Servizi Sociali, Programmazione Sociosanitaria USR Veneto</i></div>
								

								<div className='text-center'>
												
							<button className="btn-sm   fw-bolder cv-btn" onClick={() => toggle(2)}
								>
								  {show[2] ? 'Leggi di meno' : 'Leggi di più'}</button>
								  </div>
								{ show[2] && 
								<div>
								<p>Esperto esterno di LIS/LISt selezionato per le province di Vicenza, Verona, Padova, Treviso, Verona e Venezia</p>
								
									<li className='small'> IC 17 Montorio di Verona - Formazione docenti</li>
									<li className='small'>IC Carlo Ridolﬁ di Lonigo - Formazione docenti</li>
									<li className='small'>IIS Ruzza di Padova - Formazione docenti</li>
									<li className='small'>Scuola Primaria Dante Broglio di Colognola ai Colli</li>
									<li className='small'>ISISS Marco Minghetti di Legnago</li>
									<li className='small'>Scuola Primaria A. Volta di Padova</li>
									<li className='small'>Scuola Primaria Ippolito-Nievo di Padova</li>
									<li className='small'>Scuola Primaria Santa Rita di Padova</li>
									<li className='small'>Scuola Primaria Megliadino di San Fidenzio</li>
									<li className='small'>Scuola Primaria Guglielmo Ciardi di Treviso</li>
									<li className='small'>Scuola Primaria Silvio Pellico di Signoressa</li>
									<li className='small'>Istituto Tecnico Tecnologico Economico Galileo Galilei di Arzignano</li>
									<li className='small'>IIS Boscardin di Vicenza</li>
									<li className='small'>Scuola Primaria di Cassola Centro</li>
									<li className='small'>IC 13 “PRIMO LEVI” DI Verona - Formazione docenti</li>
									<li className='small'>IC Badia Trecenta di Rovigo - Formazione docenti</li>
									<li className='small'>Scuola Primaria Villanova del Ghebbo</li>
									<li className='small'>Scuola Primaria Cologna Veneta</li>
									<li className='small'>Scuola Primaria Sant'Andrea</li>
									<li className='small'>IIS Colombo</li>
									<li className='small'>Scuola Primaria di Minerbe</li>
									<li className='small'>Scuola dell'Infanzia Taglio di Po'</li>
																							
							</div> }
							</div>
							<br />
							
						</div>
						
					</div>
				</div>
				
			
		
			
			{/* [3] RAI*/}
			
				<div className="card-body p-5">
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
								<div className="small text-muted">02/2020 – Attuale </div>
								
								<div className="fw-bolder job"> Interpretazioni LIS di opere musicali, teatrali e cinematografiche  </div>
								<div className="medium text-muted"><i>Rai Radiotelevisione italiana S.P.A.</i></div>
								<div className="medium text-muted">Roma</div>
							
							<br/>
						
						
							<div className=' text-center'>
												
								<button className="btn-sm   fw-bolder cv-btn" onClick={() => toggle(3)}
									>
										{show[3] ? 'Leggi di meno' : 'Leggi di più'}</button>
										</div>
								{ show[3] && 
							<div>
								<p>Interpretazioni LIS di opere liriche:</p>
								
									<li className='small'>Tosca di Puccini Teatro Carlo Fenice</li>
									<li className='small'>Carmen di Georges Bizet Teatro San Carlo di Napoli</li>
									<li className='small'>Cenerentola di Gioachino Rossini Teatro Dell'Opera di Roma</li>
									<li className='small'>La Traviata Teatro dell'Opera di Roma</li>
									<li className='small'>Rigoletto di G. Verdi Teatro Massimo di Palermo in coproduzione con il teatro Regio di Torino, l'Opéra de Wallonie Liège e la Shaanxi Opera House</li>
								<br/>
								
								<p>Interpretazioni LIS di eventi radiotelevisivi musicali:</p>
								
									<li className='small'>Eurovision Song Contest 2024 - Eurovision LIS</li>
									<li className='small'>Festival di Sanremo 2024 - Sanremo Accessibile 2024</li>
									<li className='small'> Eurovision Song Contest 2023 - Eurovision LIS</li>
									<li className='small'> Festival di Sanremo 2023 - Sanremo Accessibile 2023 </li>
									<li className='small'> Eurovision Song Contest 2022 - Eurovision LIS</li>
									<li className='small'>Festival di Sanremo 2022 - Sanremo Accessibile 2022</li>
									<li className='small'> Festival di Sanremo 2021 - Sanremo LIVE LIS 2021</li>
									<li className='small'> Festival di Sanremo 2020 - Sanremo LIVE LIS 2020</li>
								<br/>

								<p>Interpretazioni LIS cinematografiche:</p>
								<p className='small'>Io sono Mia diretto da Riccardo Donna</p>

							</div>
							}
							<br />
							<br />
							{/* video Facebook */}
							{/* Link non embed, ancora da correggere. Da tentare: METTERE LO STILE IN CSS E CAMBIARE HEIGHT CON MEDIAQUERIES */}
							
							{/* <div className='col text-center text-lg-center mb-4 mb-lg-0'>
				<i>Interpretazione LIS all'Eurovision Song Contest 2022:</i>
				
				<div style={{position: 'relative', height: '0', paddingTop: '100%',
				
				}}>
					
				<FacebookEmbed className="fbVideo" 	
				style={{marginTop:"15px", position: 'absolute', top: '0',left:'0', width: '100%', height: '47%' }} 
				url="https://www.facebook.com/watch/?v=2243990395767736&ref=sharing"   /> 
				


				</div>

				</div> */}
				{/*  YouTube embed link with responsive iframe */}
				
				
				<div class="col text-center text-lg-center mb-4 mb-lg-0" >
					

				<i style={{letterSpacing: '0.2px', wordSpacing: '2.3px'}}>Interpretazione LIS di 'E yo mamma' di Coez:</i>
					<div class="ratio ratio-16x9">				
				<iframe title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" style={{marginTop:"10px"}}allowfullscreen src="https://www.youtube.com/embed/kaVZNDIZdUQ?si=rMEyMgHwVe1hrfkc"
				srcDoc={srcDocContent}
				/>
				
								</div>	
				</div> 
					</div>
					</div>
				</div>
				</div>

				
				
			{/* [4] La Fenice*/}	
			
				<div className="card-body p-5">
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							<div className="small text-muted">10/2020 – 05/2021 </div>
								<div className="fw-bolder job"> Interpretazione LIS di opere teatrali per la scuola </div>
								<div className="medium text-muted"><i>Teatro La Fenice </i></div>
								<div className="medium text-muted">Venezia</div>
							<div className=' text-center'>
												
							<button className="btn-sm  fw-bolder cv-btn" onClick={() => toggle(4)}
								>
								  {show[4] ? 'Leggi di meno' : 'Leggi di più'}</button>
								  </div>

								{ show[4] && 
							<div>
								<p>Interpretazione LIS di adattamenti per la scuola per il progetto "La Fenice ti racconta on line" delle opere liriche:</p>
								
									<li className='small'>Turandot di G. Puccini</li>
									<li className='small'>Madama Butterfly di G. Puccini</li>
									<li className='small'>Rigoletto di G. Verdi</li>
									<li className='small'>Il Flauto Magico di W.A. Mozart</li>
									<li className='small'>La Traviata di G. Verdi</li>
								
								<br/>
								<p>Interpretazione LIS di brani musicali:</p>
								
								<li className='small'>Baba Jaga, Quadri di un'esposizione di M.P. Musorgkij</li> 
								<li className='small'>Balletto dei pulcini nei loro gusci, Quadri di un'esposizione di M.P className='small'. Musorgskij</li>
								<li className='small'>Tuileries, Quadri di un'esposizione di M.P. Musorgskij</li> 
								<li className='small'>Vecchio Castello, Quadri di un'esposizione di M.P. Musorgskij</li> 
								<li className='small'>Gnomus, Quadri di un'esposizione di M.P. Musorgskij</li> 
								<li className='small'>Va' Pensiero di Giuseppe Verdi</li>
								</div>
								}
								<br /> <br />
						
								{/* video non embed Instagram, disattivato fino a quando trovo soluzione */}
								
								{/* <div className='col text-center text-lg-center mb-4 mb-lg-0'>
								<i>Interpretazione della Turandot di Puccini:</i>

								<div style={{position: 'relative', height: '0', paddingTop: '100%',
				
				}}>
								<InstagramEmbed style={{marginTop:"15px", position: 'absolute', top: '0',left:'0', width: '100%', height: '100%' }}  url="https://www.instagram.com/reel/COUzsQ2Iuf6/"   />
								</div>
								</div>	  */}
								</div>
								
							</div>
					</div>
				</div>
			
			
			
			
			{/* [5] CODA Italia */}
			
				<div className="card-body p-5" style={{marginBottom: "-100px"}}>
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							<div className="small text-muted">06/2022 – 07/2022 </div>
								<div className="fw-bolder job">Educatore per bambini e ragazzi sordi e figli di sordi </div>
								
								<div className="medium text-muted"><i>CODA Italia e Sorda Picena</i></div>
								<div className="medium text-muted">San Benedetto del Tronto</div>
								
								<div className='text-center'>
												
							<button className="btn-sm  fw-bolder cv-btn" onClick={() => toggle(5)}
								>
								  {show[5] ? 'Leggi di meno' : 'Leggi di più'}</button>
								  </div>
								{ show[5] && 
								
								<div>
								<p>
								Educatore specializzato durante i campi estivi organizzati dalle associazioni CODA Italia e Sorda Picena dedicati a bambini e ragazzi sordi e ﬁgli o parenti di sordi.	
								</p>
							</div>
								}

							</div>
							<br />
							
						</div>
						
					</div>
				</div>
				
			

			{/* Altre esperienze*/}	
			
				<div className="card-body p-5">
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							
								<div className=" fw-bolder">Altre esperienze</div>
								<br/>
								<div > 
									<p>
										Attività di interpretariato e traduzione LIS/LISt per eventi, conferenze, corsi di formazione, seminari, convegni, riunioni, visite mediche, colloqui, ecc.
										
									</p>
									<p>Corsi privati di lingua dei segni italiana (LIS) per bambini, ragazzi e adulti.</p>
										Insegnate di LIS per cooperative ed enti del terzo settore.
								</div>
								
							</div>
							<br />
							
						</div>
						
					</div>
				</div>
				
			
			</div>	

			
</section>

		{/* Studi Section*/}
		
<section>
		<div className="d-flex align-items-center justify-content-between mb-4">
				<h2 className="first-gold fw-bolder mb-0">I miei studi</h2>
			</div>
								
			{/*  Card */}
		<div className="card shadow border-0 rounded-4 mb-5">
			{/* Master */}
			<div className="card-body p-5" style={{marginBottom: "-100px"}}>
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							<div className="small text-muted">04/2024 – in corso </div>
								<div className="fw-bolder">Master di Primo Livello in "Sign Language Interpretation in Multilingual Settings"</div>
								
								<div className="medium text-muted"><i>Università di Siena in collaboraizone con Mason Perkins Deafness Fund </i></div>
								
							</div>
							<br />
							
						</div>
						
					</div>
				</div>
					{/* Corso IS */}
				<div className="card-body p-5" style={{marginBottom: "-100px"}}>
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							<div className="small text-muted">03/2023 – 06/2023 </div>
								<div className="fw-bolder">Corso di IS (International Sign)</div>
								
								<div className="medium text-muted"><i>Ente Nazionale Sordi sezione provinciale di Milano </i></div>
								
							</div>
							<br />
							
						</div>
						
					</div>
				</div>
				
			
			{/* Corso LIS */}
				<div className="card-body p-5" style={{marginBottom: "-100px"}}>
					<div className="row align-items-center gx-5" >
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							<div className="small text-muted">04/2021 – 06/2023 </div>
								<div className=" fw-bolder">Corso di specializzazione per interpreti LIS</div>
								
								<div className="medium text-muted"><i>Ente Nazionale Sordi sezione provinciale di Bergamo </i></div>
								
							</div>
							<br />
							
						</div>
						
					</div>
				</div>
				
			
			{/* [6] Laurea Magistrale*/}
			
				<div className="card-body p-5" style={{marginBottom: "-80px"}} >
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							<div className="small text-muted">07/2020  </div>
								<div className="fw-bolder">Laurea Magistrale in Scienze del linguaggio</div>
								
								<div className="medium text-muted"><i>Università Ca' Foscari di Venezia </i></div>
							<br />
							<div className=' text-center'>
												
							<button className="btn-sm  fw-bolder  cv-btn" onClick={() => toggle(6)}
								>
								  {show[6] ? 'Leggi di meno' : 'Leggi di più'}</button>
								  </div>
								{ show[6] && 
								<div>
							<p>Curriculum: Linguistica per la sordità e i disturbi del linguaggio</p>
							<p>Lingue di specializzazione: Inglese e lingua dei segni italiana (LIS)</p>
							<div className="small">
							<p><b>Campo di studio:</b> LM-39 Linguistica
							<br/>
							<b>Voto finale:</b> 106/110 
							<br/>
							<b>Tesi:</b> L'uso clinico delle lingue dei segni nella riabilitazione linguistica dei soggetti con ASD </p>
							</div>
								
							</div>	
								
								}
						</div>
						</div>
					</div>
				</div>
				

			{/*[7] Laurea Triennale*/}
			
				<div className="card-body p-5" style={{marginBottom: "-80px"}}>
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
								<div className="small text-muted">07/2018 </div>
								<div className="fw-bolder">Laurea Triennale in Lingue, Civiltà e Scienze del Linguaggio</div>
								
								<div className="medium text-muted"> <i>Università Ca' Foscari di Venezia </i></div>
								
								<br />
								<div className=' text-center'>
												
							<button className="btn-sm  fw-bolder  cv-btn" onClick={() => toggle(7)}
								>
								  {show[7] ? 'Leggi di meno' : 'Leggi di più'}</button>
								  </div>
								{ show[7] && 
								<div>
								<p>Curriculum:  Linguistico, filologico e glottodidattico</p>
								<p>Lingue di specializzazione: Inglese e lingua dei segni italiana (LIS)</p>
								<div className="small">
								<p> <b>Voto finale:</b> 104/110 
								<br/>
								<b>Tesi:</b> Sordità Queer: storie di vita di sordi dalla comunità LGBT+ </p>
								</div>
								</div>
								
								}
							</div>
							</div>
					</div>
				</div>
			
		
			{/*Corso Linguaggio*/}
		
				<div className="card-body p-5">
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							
							<br />
							<div className="bg-light p-4 rounded-4">
							<div className="small text-muted">09/2022 - 11/2022 </div>
								<div className="fw-bolder">Corso di Linguaggio, Identità di Genere e Lingua Italiana</div>
								
								<div className="medium text-muted"> <i>Università Ca' Foscari di Venezia </i> </div>
								</div>
							<br />
							


						</div>
						
				</div>
				</div>
				
						
		</div>
</section>	



							{/* Divider*/}
							<div className="pb-5"></div>
							
						</div>
					</div>
				</div>
			</main>

			{/*   Bootstrap core JS
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
         Core theme JS
        <script src="js/scripts.js"></script> */}
		</body>
	);
}

export default CurriculumVitae;
