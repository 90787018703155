import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';




function NavBar() {
	/* const showButton = () => {
		setHidden(false);
	}; */

	//const showButton = useContext(BtnContext);
	return (
		<nav className="navbar navbar-expand-lg py-3">
			<div className="container"
			>
				{/* <NavLink to="/" className="navbar-brand">
					<span className="fw-bolder first-blue">
						Nicola Noro <br />
						(LOGO)
					</span>
				</NavLink> */}
				
							
				{/* Togliere Home è accessibile? */}
				<ul>
				<li className="nav-item">
					<NavLink
					id='svg-home'
					to="/"
					exact
					className={({ isActive }) =>
						isActive ? 'active' : 'nav-link'
					}>
			{/* Home SVG icon */}		
					{/* <svg 
					id='
					navbar-svg'
					aria-labelledby='icontitle icondesc'
					baseProfile="tiny"
					viewBox="0 0 24 24"
					fill="#1d7c99"
					>
						<title id='icontitle'> icona Home</title>
						<desc id='icondesc'>icona a forma di casa per la home del sito</desc>
						<path d="M22.262 10.468c-3.39-2.854-9.546-8.171-9.607-8.225L12 1.68l-.652.563c-.062.053-6.221 5.368-9.66 8.248A2.042 2.042 0 001 12a2 2 0 002 2h1v6a2 2 0 002 2h12a2 2 0 002-2v-6h1a2 2 0 002-2c0-.598-.275-1.161-.738-1.532zM14 20h-4v-5h4v5zm4-8l.002 8H15v-6H9v6H6v-8H2.999C5.764 9.688 10.314 5.773 12 4.32c1.686 1.453 6.234 5.367 9 7.681L18 12z" />
					</svg> */}
					{/* <container className="logo-container" style={{width:'6vw'}}> */}
				<img alt='logo Nicola Noro, interprete LIS. Clicca per tornare alla home page' src='../logo.svg' style={{width:'3.5em'}}  /> 
					{/* <span className="nav-text">Home</span> */}
					{/* </container> */}
				</NavLink> 
				</li>	
				</ul>
				{/* Toggler */}
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
					style={{border:"none" }}>
					{/* Burger menu SVG icon */}
					<svg
					id='navbar-svg'
					aria-labelledby='icontitle icondesc'
					baseProfile="tiny"
					viewBox="0 0 24 24"
					fill="currentColor"
					height="3em"
					width="3em"
					>
					<title id='icontitle'> icona Burger menu</title>
					<desc id='icondesc'>icona 'burger menu' per accedere alle altre icone della barra di navigazione</desc>
					<path d="M19 18c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1h14m0-2H5c-1.654 0-3 1.346-3 3s1.346 3 3 3h14c1.654 0 3-1.346 3-3s-1.346-3-3-3zm0-5c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1h14m0-2H5c-1.654 0-3 1.346-3 3s1.346 3 3 3h14c1.654 0 3-1.346 3-3s-1.346-3-3-3zm0-5c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1h14m0-2H5C3.346 2 2 3.346 2 5s1.346 3 3 3h14c1.654 0 3-1.346 3-3s-1.346-3-3-3z" />
					</svg>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
					
			
						<li className="nav-item">
							<NavLink
								to="/corsi"
								className={({ isActive }) =>
									isActive ? 'active' : 'nav-link'
								}>
								
					{/* Corsi SVG icon */}
					<svg
					id='navbar-svg'
					baseProfile="tiny"
					viewBox="0 0 24 24"
					fill="#1d7c99"
					height="3em"
					width="3em"
					aria-labelledby='icontitle icondesc'
					>
					<title id='icontitle'> icona Tocco</title>
					<desc id='icondesc'>icona a forma di cappello accademico per accedere alla pagina Corsi</desc>
					<path d="M20.5 7.9S14.7 5 13.6 4.4s-1.7-.7-3 0C9.3 5 3.9 7.7 3.9 7.7c-.8.4-1.5 1.2-1.5 2s.2 1.2.2 1.2-.1.3-.3 1.5C2 13.6 2 15.1 2 15.7c0 1.5 1.3 2.6 2.2 2.7.9.1 1.6-.1 1.6-.1 1.4 1.3 3.7 2.1 6.4 2.1 4.4 0 7.8-2.2 7.8-5 0-1.1-.4-2.7-.4-2.7l1.1-.6c.9-.5 1.3-1.4 1.3-2.3-.1-.8-.6-1.5-1.5-1.9zm-8.2 10.4c-3.2 0-5.8-1.3-5.8-3l.5-2.8 4.2 2.1c.6.3 1.5.3 2.2 0l4.3-2.1.4 2.8c0 1.6-2.5 3-5.8 3zm7.3-8.1L13 13.6c-.4.2-1 .2-1.4 0l-5.7-2.9c-.2.5-.3 1.2-.3 2 0 1.4.2 2.4.2 2.9s-.3.8-.7.8H5c-.4 0-.8-.3-.8-.8s0-1.6.3-3.1c.2-.9.4-1.7.6-2.2l-.2-.1c-.4-.2-.4-.5 0-.7l6.7-3.4c.4-.2.9-.2 1.3 0s6.7 3.4 6.7 3.4c.4.2.4.5 0 .7z" />
					</svg>
  


								<span className="nav-text">Corsi</span>
							</NavLink>
						</li>
						
						<li className="nav-item">
							<NavLink
								to="/servizi"
								className={({ isActive }) =>
									isActive ? 'active' : 'nav-link'
								}>

									{/* Altri servizi SVG Icon */}
									<svg
									id='navbar-svg'
									aria-labelledby='icontitle icondesc'
									baseProfile="tiny"
									viewBox="0 0 24 24"
									fill="#1d7c99"
									height="3em"
									width="3em"
									>
									<title id='icontitle'> icona Più</title>
									<desc id='icondesc'>icona a forma di segno matematica 'più' per accedere alla pagina 'Altri servizi'</desc>

									<path d="M12 21c-1.654 0-3-1.346-3-3l.053-3.053L6.018 15C4.346 15 3 13.654 3 12s1.346-3 3-3l3.053-.054L9 6.018C9 4.346 10.346 3 12 3s3 1.346 3 3l.055 2.946L18.018 9C19.654 9 21 10.346 21 12s-1.346 3-3 3l-2.945-.053L15 18.018C15 19.654 13.654 21 12 21zm-1-8v5.018c0 .533.449.982 1 .982s1-.449 1-1v-5h5.018c.533 0 .982-.449.982-1s-.449-1-1-1h-5V6c0-.569-.449-1-1-1s-1 .449-1 1v5H6c-.569 0-1 .449-1 1s.449 1 1 1h5z" />
									</svg>

								<span className="nav-text">Altri servizi</span>
							</NavLink>
						</li>
						
						<li className="nav-item">
							<NavLink
								to="/curriculumvitae"
								className={({ isActive }) =>
									isActive ? 'active' : 'nav-link'
								}>
							{/* Su di me SVG icon */}
								<svg
																	id='navbar-svg'

								aria-labelledby='icontitle icondesc'
								baseProfile="tiny"
								viewBox="0 0 24 24"
								fill="#1d7c99"
								height="3em"
								width="3em"
								>
								<title id='icontitle'> icona Business card</title>
								<desc id='icondesc'>icona a forma di biglietto da visita per accedere alla pagina 'Su di me'</desc>
								<path d="M20 20H4c-1.654 0-3-1.346-3-3V7c0-1.654 1.346-3 3-3h16c1.654 0 3 1.346 3 3v10c0 1.654-1.346 3-3 3zM4 6c-.551 0-1 .449-1 1v10c0 .551.449 1 1 1h16c.551 0 1-.449 1-1V7c0-.551-.449-1-1-1H4zm6 9H6a1 1 0 110-2h4a1 1 0 110 2zm0-4H6a1 1 0 110-2h4a1 1 0 110 2z" />
								<path d="M18 10.5 A2 2 0 0 1 16 12.5 A2 2 0 0 1 14 10.5 A2 2 0 0 1 18 10.5 z" />
								<path d="M16 13.356c-1.562 0-2.5.715-2.5 1.429 0 .357.938.715 2.5.715 1.466 0 2.5-.357 2.5-.715 0-.714-.98-1.429-2.5-1.429z" />
								</svg>

								<span className="nav-text">Su di me</span>
							</NavLink>
						</li>
						
						
					</ul>
				</div>
			</div>
		</nav>
	);
}

export default NavBar;
