import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom';
import React from 'react';

import Home from './Pages/Home';

//components
import ErrorBoundary from './Components/ErrorBoundary';
import InscrSuccess from './Components/InscrSuccess';
import ReqSuccess from './Components/ReqSuccess';

//pages

import CurriculumVitae from './Pages/CurriculumVitae';
import Iscrizioni, { iscrizioniAction } from './Pages/Iscrizioni';
import Informazioni from './Pages/Informazioni';
import Servizi from './Pages/Servizi';
import './App.css';
import Corsi from './Pages/Corsi';
import Richieste from './Pages/Richieste';

//layout
import AppLayout from './AppLayout';

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<AppLayout />} errorElement={<ErrorBoundary />}>
			<Route path="/" element={<Home />} errorElement={<ErrorBoundary />} />
			
			<Route path="/corsi" element={<Corsi />} />
			<Route
				path="/curriculumvitae"
				element={<CurriculumVitae />}
				errorElement={<ErrorBoundary />}
			/>
			<Route
				path="/iscrizioni"
				element={<Iscrizioni />}
				action={iscrizioniAction}
				errorElement={<ErrorBoundary />}
			/>
			<Route
				path="/servizi"
				element={<Servizi />}
				errorElement={<ErrorBoundary />}
			/>
			<Route
				path="/richieste"
				element={<Richieste />}
				errorElement={<ErrorBoundary />}
			/>
			<Route
				path="/inscrsuccess"
				element={<InscrSuccess />}
				errorElement={<ErrorBoundary />}
			/>
			<Route
				path="/informazioni"
				element={<Informazioni />}
				errorElement={<ErrorBoundary />}
			/>
			<Route
				path="/reqsuccess"
				element={<ReqSuccess />}
				errorElement={<ErrorBoundary />}
			/>
		</Route>
		
	)
);
function App() {
	return <RouterProvider router={router} />;
}
export default App;
