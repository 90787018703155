import React from 'react';

import './Corsi.css';
import CardService from '../Components/CardService';

function Servizi() {
	return (
		<body className="d-flex flex-column h-100">
			<main className="flex-shrink-0">
				<div className="container px-5 my-5">
					<div className="text-center mb-5">
						<h1 className="display-5 fw-bolder mb-0">
							<span className="first-blue">Altri servizi</span>
						</h1>
					</div>
					<div className="row gx-5 justify-content-center">
						<div className="col-sm-12 col-md-12 col-lg-11 col-xl-9 col-xxl-8">
							
							<section>
								{/*  Lezioni private*/}
								<CardService
									title={'Formazione'}
									intro={'Vuoi organizzare una formazione specifica?'}
									intro2={'Contattami per definire assieme un progetto formativo'}
									description={` Ho diversi anni di esperienza come docente e formatore sui temi legati alla sordità, alle disabilità e alle lingue dei segni e sono specializzato nella formazione di formatori e docenti. Possiamo collaborare per una formazione sull'accessibità scolastica delle persone sorde, progettare formazioni riguardanti la sordità e le lingue dei segni o elaborare una formazione che verta sui temi dell'inclusione delle persone disabili. Sono attivista e formatore anche riguardo le tematiche LGBT+ e le tematiche di genere, possiamo progettare una formazione che verta sull'inclusione delle persone LGBT+ o su temi quali la discriminazione, il bullismo e l'omobitransfobia.`}
								/>{' '}
								<CardService 
								title={'Interpretariato e traduzione'}
								intro={'Ti serve un interprete o un traduttore?'}
								intro2={'Contattami per servizi di interpretariato o traduzioni a distanza o in presenza per italiano, LIS, LISt e IS'}
								description={`Sono laureato presso Ca' Foscari in Lingua dei segni italiana e specializzato nella linguistica per la sordità e i disturbi del linguaggio. Sono interprete di lingua dei segni italiana (LIS) e lingua dei segni italiana tattile (LISt) e ho ottenuto una specializzazione in International Signs (IS) presso l'Ente Nazionale Sordi di Milano.`}/>
								<CardService 
								title={'Traduzioni artistiche e musicali'}
								intro={'Vuoi rendere accessibile un evento artistico o musicale alle persone sorde?'}
								intro2={'Contattami per interpretazione e/o adattamento di spettacoli, eventi musicali ed eventi artistici'}
								description={`Ho collaborato con diverse compagnie teatrali per rendere accessibili alcuni spettacoli alle persone sorde, ho collaborato con RAI per la traduzione di opere liriche, film ed eventi musicali quali Sanremo e l'Eurovision Song Contest e con Il Teatro La Fenice di Venezia per l'adattamento in LIS di opere liriche per i più piccoli.`}/>
							</section>

						
							
							
							
							
						</div>
					</div>
				</div>
			</main>

			{/*   Bootstrap core JS
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
         Core theme JS
        <script src="js/scripts.js"></script> */}
		</body>
	);
}

export default Servizi;
