import React from 'react';

import './Corsi.css';
import CardCourse from '../Components/CardCourse';

function Corsi() {
	return (
		<body className="d-flex flex-column h-100">
			<main className="flex-shrink-0">
				<div className="container px-5 my-5">
					<div className="text-center mb-5">
						<h1 className="display-5 fw-bolder mb-0">
							<span className="first-blue">I miei corsi</span>
						</h1>
						<br />
						<h3 className="display-8 fw-bolder mb-0">
							<span style={{color: "#D51515", textDecoration: "underline #D51515 2px"}}>Scadenza iscrizioni: 8 Gennaio 2024</span>
						</h3>
					</div>
					<div className="row gx-5 justify-content-center">
						<div className="col-lg-11 col-xl-9 col-xxl-8 col-sm-12 col-md-12">
							
							<section>
								{/*  Corso Sensibilizzazione*/}
								<CardCourse
									title="Corso Sensibilizzazione"
									duration="10 ore"
									/* deadline="10 ottobre" */
									date="13 Gen - 16 Feb 2024"
									days={'Martedì'}
									hours={'20:00 - 22:00'}
									price="150 euro"
									description={`Questo corso ha lo scopo di avvicinare alla sordità e
													alla lingua dei segni italiana (LIS). Alla fine del
													corso lo studente avrà abbattuto i propri stereotipi
													sulla sordità e sulle lingue dei segni, sarà in grado
													di presentarsi in LIS e capire una persona che si
													presenta. Saprà parlare dei propri interesse e
													chiedere gli interessi dell'interlocutore. Conoscerà
													la grammatica della frase semplice in LIS, i parametri
													formazionali del segno e saprà adottare strategie
													comunicative efficaci per interfacciassi con una
													persona sorda. Alla fine del corso sarà inviato un
													attestato di partecipazione.`}
								/>{' '}
								{/*  Corso Base*/}
								{/* <CardCourse
									title="Corso Base"
									duration="30 ore"
									deadline="10 Ottobre"
									date="16 Ott - 4 Dic 2023"
									days={'Lunedì e Giovedì'}
									hours="18:00 - 20:00"
									price="450 euro"
									description={`Questo corso ha lo scopo di fornire le basi
									riguardanti la sordità e la lingua dei segni italiana
									(LIS). Alla fine del corso lo studente avrà abbattuto
									i propri stereotipi sulla sordità e sulle lingue dei
									segni, sarà in grado di presentarsi in LIS e capire
									una persona che si presenta. Saprà parlare dei propri
									interesse e chiedere gli interessi dell'interlocutore.
									Saprà esprimersi al passato, al presente e al futuro
									utilizzando in maniera adeguata gli avverbi di tempo.
									Saprà chiedere e raccontare del proprio lavoro e del
									proprio percorso di studi. Sarà in grado di parlare
									della propria famiglia. Conoscerà la grammatica della
									frase semplice, delle frasi interrogative e negative
									in LIS, saprà coniugare i verbi, riconoscendone le
									classi di appartenenza, correttamente al passato, al
									presente e al futuro. Conoscerà i parametri
									formazionali del segno e saprà adottare strategie
									comunicative efficaci per interfacciassi con una
									persona sorda. Alla fine del corso sarà inviato un
									attestato di partecipazione.`}
								/>{' '} */}
								{/* Corso Base Docenti*/}
								<CardCourse
									title="Corso Base Docenti"
									duration="30 ore"
									/* deadline="10 Ottobre" */
									date="15 Gen - 21 Mar 2024"
									days="Martedì e Giovedì"
									hours="18:30 - 20:00"
									price="550 euro"
									description={`Questo corso ha lo scopo di fornire le basi
									riguardanti la sordità e la lingua dei segni italiana
									(LIS). Alla fine del corso lo studente avrà abbattuto
									i propri stereotipi sulla sordità e sulle lingue dei
									segni, sarà in grado di presentarsi in LIS e capire
									una persona che si presenta. Saprà parlare delle
									materie scolastiche, degli spazi e delle figure della
									scuola. Saprà esprimersi al passato, al presente e al
									futuro utilizzando in maniera adeguata gli avverbi di
									tempo. Saprà utilizzare correttamente i numeri. Saprà
									chiedere e raccontare del proprio lavoro e del proprio
									percorso di studi. Saprà adattare un testo affinché
									sia maggiormente accessibile ad una persona sorda.
									Saprà creare mappe e schemi maggiormente accessibili.
									Conoscerà le strategia da adottare per rendere la
									propria classe e la propria scuola maggiormente
									inclusive. Conoscerà la grammatica della frase
									semplice, delle frasi interrogative e negative in LIS.
									Saprà coniugare i verbi, riconoscendone le classi di
									appartenenza, correttamente al passato, al presente e
									al futuro. Conoscerà i parametri formazionali del
									segno e saprà adottare strategie comunicative efficaci
									per interfacciassi con una persona sorda. Alla fine
									del corso sarà inviato un attestato di partecipazione.`}
								/>
							</section>

							

							
							
						</div>
					</div>
				</div>
			</main>

			{/*   Bootstrap core JS
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
         Core theme JS
        <script src="js/scripts.js"></script> */}
		</body>
	);
}

export default Corsi;
