/* Pagina inutilizzata */


import React, { useRef, useState, useEffect } from 'react';
import { Form, redirect, useActionData, useNavigate} from 'react-router-dom';
import emailjs from '@emailjs/browser';

function Informazioni() {
	const data = useActionData();

	const form = useRef();
	
const navigate = useNavigate();


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('NicolaNoro', 'courses_form', form.current, '7xdgBEu8gT_VFwYt5')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

	  e.target.reset()

	  
	  navigate('/inscrsuccess');


	 
  };


	return (

	/*  */


		<body className="d-flex flex-column">
			<main className="flex-shrink-0">
				<section className="py-5">
					<div className="container px-5">
						<div className="bg-light p-4 rounded-4">
							<div className="bg-light rounded-4 py-5 px-4 px-md-5">
								<div className="text-center mb-5">
									<h1 className="first-blue fw-bolder">Richiesta informazioni</h1>
									<br />
									<p className="lead fw-normal text-muted mb-0">
										
									</p>
								</div>
								<div className="row gx-5 justify-content-center">
									<div className="col-lg-8 col-xl-6">
										{/* 'action' will be used in the router /iscrizioni later, when we will add a function */}


										

										{/* <Form method="post" action="/iscrizioni"> */}
										<Form ref={form} onSubmit={sendEmail}>
											<div className="mb-3 form-check">
												<input
													type="radio"
													name="radio"
													id="radio1"
													className="form-check-input"
													value="Corso Sensibilizzazione - 100 euro"
												/>
												<label
													className="lead fw-normal text-muted mb-0 "
													htmlFor="radio1"
													style={{ marginLeft: '0.5rem' }}>
													Corso Sensibilizzazione
												</label>
											</div>
											<div className="mb-3 form-check">
												<input
													type="radio"
													name="radio"
													id="radio2"
													className="form-check-input"
													value="Corso Base - 250 euro"
												/>
												<label
													className="lead fw-normal text-muted mb-0 "
													htmlFor="radio2"
													style={{ marginLeft: '0.5rem' }}>
													Corso Base
												</label>
											</div>

											<div className="mb-3 form-check">
												<input
													type="radio"
													name="radio"
													id="radio3"
													className="form-check-input"
													value="Corso Base Docenti - 350 euro"
												/>
												<label
													className="lead fw-normal text-muted mb-0 "
													htmlFor="radio3"
													style={{ marginLeft: '0.5rem' }}>
													Corso Base Docenti
												</label>
											</div>

											<br />
											<div className="form-floating mb-3">
												<input
													className="form-control"
													type="text"
													name="name"
																										required
												/>
												<label htmlFor="name">Nome e cognome</label>
												{/* <div
													className="invalid-feedback"
													data-sb-feedback="name:required">
													Scrivi il tuo nome e cognome.
												</div> */}
												
											</div>

											<div className="form-floating mb-3">
												<input
													className="form-control"
													type="text"
													name="email"
													required
												/>
												<label htmlFor="email">Email</label>

												{/* <div
													className="invalid-feedback"
													data-sb-feedback="email:required">
													Scrivi il tuo indirizzo email.
												</div>
												<div
													className="invalid-feedback"
													data-sb-feedback="email:email">
													Email is not valid.
												</div> */}
											</div>

											<div className="form-floating mb-3">
												<input
													className="form-control"
													type="text"
													name="phone"
													required
												/>
												<label htmlFor="phone">Nr. di telefono</label>
												<div
													className="invalid-feedback"
													data-sb-feedback="phone:required">
													Inserisci un numero di telefono.
												</div>
											</div>

											<div className="form-floating mb-3">
												<textarea
													className="form-control"
													type="text"
													name="message"
													style={{ height: '12rem' }}
												/>
												<label htmlFor="message">Commenti</label>
												{/* <div
													className="invalid-feedback"
													data-sb-feedback="message:required">
													A message is required.
												</div> */}
											</div>

											<button
																						
												type="submit"
												values="Send"
												className="btn-first-gold btn-lg px-5 py-3 fs-6 fw-bolder "
												/* NOT button optimal align */
												style={{
													marginTop: '5%',
													width: '100%',
													border: 'inherit'
												}}>
												Invia
											</button>
											
											{data && data.error && <p>{data.error}</p>}
										</Form> 
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</body>
	);
}

export default Informazioni;

export async function iscrizioniAction({ request }) {
	const data = await request.formData();
	console.log(data);

	const submission = {
		checkbox1: data.get('checkbox1'),
		checkbox2: data.get('checkbox2'),
		checkbox3: data.get('checkbox3'),
		name: data.get('name'),
		email: data.get('email'),
		phone: data.get('phone'),
		message: data.get('message'),
	};
	console.log(submission);

	//SIMULATION OF send post request to the server
	if (submission.message.length > 0) {
		return { error: 'Testing Error message' };
	}
	//redirect to home page
	return redirect('/formsuccess');
}
