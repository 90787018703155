import React from 'react';
import { NavLink } from 'react-router-dom';

import './Card.css'

function CardCourse({
	title,
	duration,
	/* deadline, */
	date,
	days,
	hours,
	price,
	description,
}) {
	return (
		<>
			<div className="d-flex align-items-center justify-content-between mb-4">
				<h2 className="first-gold fw-bolder mb-0">{title}</h2>
			</div>

			<div className="card shadow border-0 rounded-4 mb-5">
				<div className="card-body p-5">
					<div className="row align-items-center gx-5">
						<div className="col text-center text-lg-start mb-4 mb-lg-0">
							<div className="bg-light p-4 rounded-4">
								<div className=" first-blue fw-bolder mb-2">Online</div>
								<div className="first-blue small fw-bolder">{duration}</div>
								
								
							</div>
							<br />
							<div className="bg-light p-4 rounded-4">
								<div className=" fw-bolder" style={{paddingBottom:"10px"}}>Quando</div>
								<div className="small text-muted">{date} </div>
								
								<div className="small text-muted"style={{paddingTop:"5px"}}>{days}</div>
								<div className="small text-muted">{hours}</div>
							</div>
							<br />
							<div className="bg-light p-4 rounded-4">
								<div className="fw-bolder">Costo </div>
								<div className="small text-muted">{price}</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div>{description}</div>
						</div>
					</div>
				</div>
				<div className="d-grid justify-content-sm-center 
				justify-content-md-center justify-content-xxl-center justify-content-xl-center mb-3">
				<NavLink
					to="/iscrizioni"
					className="btn-first-gold btn-lg px-5 py-3 fs-6 fw-bolder card-btn"
					
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						
					}}>
					{' '}
					<span className='
				card-txt'>Iscrizione</span>
				</NavLink>
				</div>
				<div className="d-grid justify-content-sm-center 
				justify-content-md-center justify-content-xxl-center justify-content-xl-center mb-3">
				<a style={{
						marginRight: 'auto',
						marginLeft:'auto',
						marginBottom: '5vh',
						
					}} href="mailto:info@nicolanoro.com">
				<button
					className="btn btn-second-blue btn-lg px-5 py-3 fs-6 fw-bolder card-btn-info"
					>
				<span className="visually-hidden">Scrivimi per chiedere informazioni</span>
					{' '}
				<span className='
				card-txt'> Informazioni</span>	
				</button>
				</a>
				</div>
					
				</div>
			
		</>
	);
}

export default CardCourse;
